@media screen and (max-width: 600px) {
  #information h3 {
    font-size: 20px;
    font-weight: normal;
    text-align: center;
  }
  #information h3 span {
    font-size: 20px;
  }

  #news {
    float: none;
    width: auto;
    padding: 30px 0 0;
  }

  #blog {
    float: none;
    width: auto;
    padding: 30px 0 0;
  }
}
#information {
  padding: 0;
}
#information h3 {
  margin-bottom: 25px;
  font-size: 24px;
  font-weight: bold;
  line-height: 44px;
}
#information h3 span {
  display: inline-block;
  width: 44px;
  height: 44px;
  margin-right: 12px;
  font-size: 24px;
  line-height: 44px;
  color: #fff;
  text-align: center;
  background-color: #ff7f00;
  border-radius: 22px;
}

#news {
  float: left;
  width: 60%;
}
#news ul li .date {
  float: left;
  padding: 12px 0;
  font-size: 14px;
  line-height: 1.4;
}
#news ul li .title {
  padding: 12px 0;
  padding-left: 120px;
  font-size: 14px;
  line-height: 1.4;
}

#blog {
  float: left;
  width: 40%;
}
#blog ul li {
  margin-top: 15px;
  overflow: hidden;
}
#blog ul li figure {
  float: left;
  width: 150px;
}
#blog ul li figure img {
  width: 100%;
}
#blog ul li .date {
  padding-left: 160px;
  font-size: 12px;
}
#blog ul li .title {
  padding-left: 160px;
  font-size: 14px;
  line-height: 1.4;
}