@media screen and(max-width: 600px) {
  #information h3 {
    font-size: 20px;
    font-weight: normal;
    text-align: center;

    span {
      font-size: 20px;
    }
  }

  #news {
    float: none;
    width: auto;
    padding: 30px 0 0;
  }

  #blog {
    float: none;
    width: auto;
    padding: 30px 0 0;
  }
}

#information {
  padding: 0;

  h3 {
    margin-bottom: 25px;
    font-size: 24px;
    font-weight: bold;
    line-height: 44px;

    span {
      display: inline-block;
      width: 44px;
      height: 44px;
      margin-right: 12px;
      font-size: 24px;
      line-height: 44px;
      color: #fff;
      text-align: center;
      background-color: #ff7f00;
      border-radius: 22px;
    }
  }
}

#news {
  float: left;
  width: 60%;

  ul li {
    .date {
      float: left;
      padding: 12px 0;
      font-size: 14px;
      line-height: 1.4;
    }

    .title {
      padding: 12px 0;
      padding-left: 120px;
      font-size: 14px;
      line-height: 1.4;
    }
  }
}

#blog {
  float: left;
  width: 40%;

  ul li {
    margin-top: 15px;
    overflow: hidden;

    figure {
      float: left;
      width: 150px;

      img {
        width: 100%;
      }
    }

    .date {
      padding-left: 160px;
      font-size: 12px;
    }

    .title {
      padding-left: 160px;
      font-size: 14px;
      line-height: 1.4;
    }
  }
}
